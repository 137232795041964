export default [
    {
        headerName: 'meta_error_message',
        field: 'meta_error_message',
        filterParams: {
            filterOptions: ['contains', 'equals', 'startsWith', 'endsWith'],
        },
    },
    {
        headerName: 'meta_error_code',
        field: 'meta_error_code',
        filterParams: {
            filterOptions: ['contains', 'equals', 'startsWith', 'endsWith'],
        },
    },
    {
        headerName: 'meta_error_row_number',
        field: 'meta_error_row_number',
        filterParams: {
            filterOptions: ['equals'],
        },
    },
    {
        headerName: 'meta_batch_id',
        field: 'meta_batch_id',
        filterParams: {
            filterOptions: ['contains', 'equals', 'startsWith', 'endsWith'],
        },
        initialHide: true,
    },
    {
        headerName: 'meta_timestamp',
        field: 'meta_timestamp',
        filterParams: {
            filterOptions: ['equals'],
        },
    },
    {
        headerName: 'meta_filename',
        field: 'meta_filename',
        filterParams: {
            filterOptions: ['contains', 'equals', 'startsWith', 'endsWith'],
        },
        initialHide: true,
    },
]
