import api from '@/api/api'
import { errorSnackbar } from '@/helpers/snackbar'

const BASE_URL = '/api/opensearch'

const displayError = async (resp, message = 'Error') => {
    const subtext = resp?.data.error
    await errorSnackbar({ message, subtext })
}

export default {
    async get(query, index) {
        try {
            return await api.get(BASE_URL, { params: { index, query } })
        } catch (err) {
            await displayError(err.response, `Error fetching from index ${index}`)
            return err
        }
    },
    async getMapping(index) {
        try {
            return await api.get(`${BASE_URL}/mappings`, { params: { index } })
        } catch (err) {
            await displayError(err.response, `Error fetching column mappings from index ${index}`)
            return err
        }
    },
}
