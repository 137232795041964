import api from '@/api/opensearch'

export default {
    data: () => ({
        items: [],
        mappings: [],
        metadata: {},
        columnDefs: [],
    }),
    computed: {
        queryIndex() {
            return `default_query_index`
        },
        defaultFilters() {
            return []
        },
    },
    methods: {
        async fetchData({ from = 0, size = 100, sort = [], filters = [] }) {
            const filter = [...filters, ...this.defaultFilters]

            const query = {
                from,
                size,
                query: { bool: { filter } },
                sort,
                track_total_hits: true,
            }

            const queryString = JSON.stringify(query)
            const resp = await api.get(queryString, this.queryIndex)

            this.items = resp.data?.items || []
            this.metadata = resp.data?.metadata || {}
        },
        /*
         * Steps to initial loading log data
         * - Fetch the mappings for the columns
         * - Fetch the data related to this log
         * - Set the columns, optional default column defs
         *
         * Set column definition from the mappings provided by opensearch.
         * Default column definitions will be in the front of the array so they will appear
         * in the grid column order first
         */
        async fetchGridData(defaultColumnDefs = []) {
            const { data } = await api.getMapping(this.queryIndex)
            this.mappings = data || []

            /*
             * Fetch an initial row of data so we can build the column headers. Its important
             * to do this before the serverSideDatasource is called. If don't set these early
             * then the saved user preferences (sorting and hidden columns)
             * don't work correctly
             */
            await this.fetchData({ size: 1 })

            /*
             * Do not load the columns definitions more than once. If the column definitions change
             * it causes the grid to reload
             *  - Example
             *      fetching empty data (there is no columns to set) this reloads the grid
             */
            if (this.columnDefs.length) {
                return
            }

            const top = this.items[0] || {}
            const options = {
                text: ['contains', 'equals', 'startsWith', 'endsWith'],
                long: ['equals'],
            }

            this.columnDefs = Object.keys(top).reduce((acc, headerName) => {
                if (defaultColumnDefs.find((d) => d.headerName === headerName)) {
                    return acc
                }
                const fieldType = this.mappings[headerName]?.type

                acc.push({
                    headerName,
                    field: headerName,
                    filterParams: {
                        filterOptions: options[fieldType] || ['equals'],
                    },
                })

                return acc
            }, defaultColumnDefs)
        },
        serverSideDatasource: function () {
            const fetch = async (params) => {
                const buildField = (columnName) => {
                    //add .keyword to keyword fields
                    if (this.mappings[columnName]?.fields?.keyword?.type === 'keyword') {
                        return `${columnName}.keyword`
                    }

                    return columnName
                }
                /*
                 * Escape elastic search reserved characters EXCEPT '*'. '*' will allow users to search for wildcards if they know how
                 * Also replace whitespace with * to allow phases to be searched correctly
                 *
                 */
                const clean = (string) =>
                    string
                        .replace(/([\!\+\-\=\<\>\&\|\(\)\[\]\{\}\^\~\?\:\\/"])/g, '\\$1')
                        .replace(/\s+/g, '*')

                const filterModel = params.api.getFilterModel()
                const columnState = params.columnApi.getColumnState()
                const pageSize = params.api.paginationGetPageSize()
                const currentPage = params.api.paginationGetCurrentPage()
                const { customQuickSearch } = params.api

                const filters = Object.keys(filterModel).map((key) => {
                    const { filter, type } = filterModel[key]
                    const fieldName = buildField(key, this.mappings)
                    let filterText = clean(filter)

                    switch (type) {
                        case 'contains':
                            filterText = `*${filterText}*`
                            break
                        case 'startsWith':
                            filterText = `${filterText}*`
                            break
                        case 'endsWith':
                            filterText = `*${filterText}`
                            break
                        default:
                            filterText = filterText
                    }

                    return {
                        query_string: {
                            query: filterText,
                            default_field: fieldName,
                        },
                    }
                }, [])

                if (customQuickSearch) {
                    filters.push({ query_string: { query: `*${clean(customQuickSearch)}*` } })
                }

                const sort = columnState.reduce((acc, { colId, sort }) => {
                    if (!sort) return acc
                    const sortName = buildField(colId)

                    acc.push({ [sortName]: { order: sort, unmapped_type: 'string' } })
                    return acc
                }, [])

                await this.fetchData({
                    from: pageSize * currentPage || 0,
                    filters,
                    sort,
                })

                return {
                    items: this.items,
                    metadata: this.metadata,
                }
            }

            return {
                getRows: async function (params) {
                    const { items, metadata } = await fetch(params)

                    params.success({ rowData: items, rowCount: metadata.count || 0 })
                },
            }
        },
    },
}
