s<template>
    <child-layout :title="title">
        <ag-grid-server-side
            v-if="gridReady"
            id="importErrorLog"
            ref="importErrorLogGrid"
            :server-side-datasource="serverSideDatasource"
            suppress-row-click-selection="true"
            :set-grid-api="setGridApi"
            :column-defs="columnDefs"
            quick-search
        />
    </child-layout>
</template>

<script>
import ChildLayout from '@/components/layout/ChildLayout'
import AgGridServerSide from '@/components/table/AgGridServerSide'
import defaultColumnDefs from '@/helpers/ag-grid/columnDef/importLog/errorLog'
import ArchiveDownloadButton from '@/components/ArchiveDownloadButton'
import { mapActions, mapGetters } from 'vuex'
import openSearchDataSourceMixin from '@/mixins/agGrid/datasource/opensearch'

export default {
    name: 'ImportErrorLog',
    components: {
        ChildLayout,
        AgGridServerSide,
    },
    mixins: [openSearchDataSourceMixin],
    data: () => ({
        importLogResult: {},
        searchDebounce: null,
        gridReady: false,
        gridApi: null,
    }),
    computed: {
        ...mapGetters({
            lea: 'getDistrictLea',
            getByField: 'importLogResults/getItemById',
        }),
        title() {
            const { fileName } = this.importLogResult
            const suffix = fileName ? ` | ${fileName || ''}` : ''

            return `Import Error Logs${suffix}`
        },
        queryIndex() {
            return `import_logs_${this.lea}`
        },
        defaultFilters() {
            const { batchId, fileName } = this.importLogResult

            //batchId must be lower case to match the batch ids saved in opensearch
            return [
                { term: { ['meta_batch_id.keyword']: batchId.toLowerCase() } },
                { term: { ['meta_filename.keyword']: fileName } },
            ]
        },
    },
    watch: {
        //Once the importLogResult have been loaded then fetchGridData from opensearch
        async importLogResult(v) {
            if (!v) {
                return
            }

            await this.fetchGridData(defaultColumnDefs)
            this.gridReady = !!Object.keys(this.importLogResult).length
        },
    },
    async mounted() {
        const { etlStatusId } = this.$route.params
        await this.fetchLogResult({ etlStatusId })
        this.importLogResult = this.getByField(etlStatusId, 'etlStatusId')
    },
    methods: {
        ...mapActions({ fetchLogResult: 'importLogResults/get' }),
        setGridApi: function (api) {
            this.gridApi = api
        },
    },
}
</script>
